export default {
  incorrectUsrPwd: 'Nome utente o password errati',
  askDeleteUser: 'Vuoi davvero eliminare questo utente?',
  askDeleteTemp: 'Vuoi davvero eliminare questo modello?',
  askDeleteEnv: 'Vuoi davvero eliminare questo ambiente',
  askDeleteInv: 'Vuoi davvero eliminare questo inventario?',
  askDeleteKey: 'Vuoi davvero eliminare questa chiave?',
  askDeleteRepo: 'Vuoi davvero eliminare questo repository?',
  askDeleteProj: 'Vuoi davvero eliminare questo progetto?',
  askDeleteTMem: 'Vuole davvero eliminare questo membro del team?',
  edit: 'Modifica',
  nnew: 'Nuovo',
  keyFormSshKey: 'SSH Key',
  keyFormLoginPassword: 'Accesso con password',
  keyFormNone: 'Nessuno',
  incorrectUrl: 'URL non corretto',
  username: 'Nome Utente',
  username_required: 'Il nome utente è obbligatorio',
  dashboard: 'Pannello di controllo',
  history: 'Storico',
  activity: 'Attività',
  settings: 'Impostazioni',
  signIn: 'Accedi',
  password: 'Password',
  changePassword: 'Cambia password',
  editUser: 'Modifica Utente',
  newProject: 'Nuovo Progetto',
  close: 'Chiudi',
  newProject2: 'Nuovo progetto...',
  demoMode: 'MODALITÀ DIMOSTRAZIONE',
  task: 'Operazione #{expr}',
  youCanRunAnyTasks: 'È possibile eseguire qualsiasi operazione',
  youHaveReadonlyAccess: 'Hai accesso in sola lettura',
  taskTemplates: 'Modelli di operazioni',
  inventory: 'Inventario',
  environment: 'Ambiente',
  keyStore: 'Cassaforte Chiavi',
  repositories: 'Repository',
  darkMode: 'Modalità Oscura',
  team: 'Team',
  users: 'Utenti',
  editAccount: 'Modifica Account',
  signOut: 'Esci',
  error: 'Errore',
  refreshPage: 'Aggiorna Pagina',
  relogin: 'Esegui di nuovo l\'accesso',
  howToFixSigninIssues: 'Come risolvere i problemi di accesso',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'In primo luogo, è necessario accedere al server su cui è in esecuzione Semaphore.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Eseguire il seguente comando sul server per visualizzare gli utenti esistenti:',
  semaphoreUserList: 'elenco utenti semaphore',
  youCanChangePasswordOfExistingUser: 'È possibile modificare la password dell\'utente esistente:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login utente123 --password {unaPasswordDiEsempio}',
  orCreateNewAdminUser: 'Oppure crea un nuovo utente amministratore:',
  close2: 'Chiudi',
  semaphore: 'SEMAPHORE',
  dontHaveAccountOrCantSignIn: 'Non hai un account o non puoi accedere?',
  password2: 'Password',
  cancel: 'Cancella',
  noViews: 'Nessuna vista',
  addView: 'Aggiungi vista',
  editEnvironment: 'Modifica Ambiente',
  deleteEnvironment: 'Modifica Ambiente',
  environment2: 'Ambiente',
  newEnvironment: 'Nuovo Ambiente',
  environmentName: 'Nome Ambiente',
  extraVariables: 'Variabili extra',
  enterExtraVariablesJson: 'Inserisci variabili extra JSON...',
  environmentVariables: 'Variabili d\'ambiente',
  enterEnvJson: 'Inserisci ambiente JSON...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'Le variabili d\'ambiente ed extra devono essere in formato JSON valido. Esempio:',
  dashboard2: 'Pannello di controllo',
  ansibleSemaphore: 'Ansible Semaphore',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Siamo spiacenti ma <%= htmlWebpackPlugin.options.title %> non funziona correttamente senza JavaScript abilitato. Si prega di abilitarlo per continuare.',
  deleteInventory: 'Elimina Inventario',
  newInventory: 'Nuovo Inventario',
  name: 'Nome',
  userCredentials: 'Credenziali Utente',
  sudoCredentialsOptional: 'Credenziali Sudo (Opzionale)',
  type: 'Tipo',
  pathToInventoryFile: 'Percorso del file Inventario',
  enterInventory: 'Inserisci inventario...',
  staticInventoryExample: 'Esempio di Inventario statico:',
  staticYamlInventoryExample: 'Esempio di Inventario YAML statico:',
  keyName: 'Nome Chiave',
  loginOptional: 'Login (Opzionale)',
  usernameOptional: 'Nome Utente (Opzionale)',
  privateKey: 'Chiave Privata',
  override: 'Sovrascrivi',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Utilizzare questo tipo di chiave per i repository HTTPS e per playbook che non utilizzano connessioni SSH.',
  deleteKey: 'Elimina chiave',
  newKey: 'Nuova Chiave',
  create: 'Crea',
  newTask: 'Nuova Operazione',
  cantDeleteThe: 'Impossibile eliminare {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle} non può essere eliminato perché utilizzato dalle seguenti risorse',
  projectName: 'Nome Progetto',
  allowAlertsForThisProject: 'Consenti gli avvisi per questo progetto',
  telegramChatIdOptional: 'ID chat di Telegram (Opzionale)',
  maxNumberOfParallelTasksOptional: 'Numero massimo di operazioni in parallelo (Opzionale)',
  deleteRepository: 'Elimina repository',
  newRepository: 'Nuovo Repository',
  urlOrPath: 'URL o percorso',
  absPath: 'percorso assoluto',
  branch: 'Branch',
  accessKey: 'Chiave di Accesso',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Credenziali di accesso al repository Git. Possono essere:',
  ifYouUseGitOrSshUrl: 'se si utilizza Git o l\'URL SSH.',
  ifYouUseHttpsOrFileUrl: 'se si utilizza HTTPS o l\'URL del file.',
  none: 'Nessuno',
  ssh: 'SSH',
  deleteProject: 'Elimina progetto',
  save: 'Salva',
  deleteProject2: 'Elimina Progetto',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Una volta eliminato un progetto, non è possibile tornare indietro. Devi essene sicuro.',
  name2: 'Nome *',
  title: 'Titolo *',
  description: 'Descrizione',
  required: 'Richiesto',
  key: '{expr}',
  surveyVariables: 'Variabili richieste',
  addVariable: 'Aggiungi variabile',
  columns: 'Colonne',
  buildVersion: 'Versione Build',
  messageOptional: 'Messaggio (Opzionale)',
  debug: 'Debug',
  dryRun: 'Simulazione',
  diff: 'Differenza',
  advanced: 'Avanzato',
  hide: 'Nascondi',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Ricorda di consentire di sovrascrivere gli argomenti della riga di comando nelle impostazioni del modello di operazione',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'Argomenti CLI (Array JSON). Esempio: [ "-i", "@mio_inventario.sh", "--private-key=/percorso/id_rsa", "-vvvv" ]',
  started: 'Iniziato',
  author: 'Autore',
  duration: 'Durata',
  stop: 'Termina',
  forceStop: 'Termina Forzatamente',
  deleteTeamMember: 'Elimina membro del team',
  team2: 'Team',
  newTeamMember: 'Nuono Membro del Team',
  user: 'Utente',
  administrator: 'Amministratore',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Definisce la versione iniziale dell\'artefatto. Ogni esecuzione incrementa la versione dell\'artefatto.',
  forMoreInformationAboutBuildingSeeThe: 'Per ulteriori informazioni sulla compilazione, consultare la sezione',
  taskTemplateReference: 'riferimento al Modello di Operazione',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Definisce quale artefatto deve essere utilizzato quando viene eseguita l\'operazione.',
  forMoreInformationAboutDeployingSeeThe: 'Per ulteriori informazioni sull\'utilizzo, consultare la sezione',
  taskTemplateReference2: 'riferimento al Modello di Operazione',
  definesAutorunSchedule: 'Definisce la ricorrenza dell\'esecuzione automatica.',
  forMoreInformationAboutCronSeeThe: 'Per ulteriori informazioni su cron, consultare la sezione',
  cronExpressionFormatReference: 'Riferimento al formato della sintassi Cron',
  startVersion: 'Versione Iniziale',
  example000: 'Esempio: 0.0.0',
  buildTemplate: 'Modello della Build',
  autorun: 'Auto-esecuzione',
  playbookFilename: 'Nome del file del Playbook *',
  exampleSiteyml: 'Esempio: sito.yml',
  inventory2: 'Inventario *',
  repository: 'Repository *',
  environment3: 'Ambiente *',
  vaultPassword: 'Password in Cassaforte',
  vaultPassword2: 'Password in Cassaforte',
  view: 'Vista',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Voglio eseguire una operazione Cron solo per i nuovi commit del repository',
  repository2: 'Repository',
  cronChecksNewCommitBeforeRun: 'Cron controlla i nuovi commit prima dell\'esecuzione',
  readThe: 'Leggi la',
  toLearnMoreAboutCron: 'per saperne di più su Cron.',
  suppressSuccessAlerts: 'Sopprimi gli avvisi di successo',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'Argomenti CLI (Array JSON). Example: [ "-i", "@mio_inventario.sh", "--private-key=/percorso/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Consenti gli argomenti CLI nell\'operazione',
  docs: 'documentazione',
  editViews: 'Modifica Viste',
  newTemplate: 'Nuovo Modello',
  taskTemplates2: 'Modelli di Operazione',
  all: 'Tutti',
  notLaunched: 'Non eseguito',
  by: 'da {user_name} il {formatDate}',
  editTemplate: 'Modifica Modello',
  newTemplate2: 'Nuovo Modello',
  deleteTemplate: 'Elimina modello',
  playbook: 'Playbook',
  email: 'Email',
  adminUser: 'Utente Amministratore',
  sendAlerts: 'Invia avvisi',
  deleteUser: 'Elimina utente',
  newUser: 'Nuovo Utente',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} Membro del Team',
  taskId: 'ID Operazione',
  version: 'Versione',
  status: 'Stato',
  start: 'Inizia',
  actions: 'Azioni',
  alert: 'Avvisa',
  admin: 'Amministratore',
  role: 'Ruolo',
  external: 'Esterno',
  time: 'Tempo',
  path: 'Percorso',
  gitUrl: 'URL Git',
  sshKey: 'Chiave SSH',
  lastTask: 'Ultima Operazione',
  task2: 'Operazione',
  build: 'Build',
  deploy: 'Compilazione',
  run: 'Esegui',
  add: 'Aggiungi',
  password_required: 'È richiesta la Password',
  name_required: 'È richiesto il Nome',
  user_credentials_required: 'Sono richieste le credenziali dell\'utente',
  type_required: 'È richiesto il Tipo',
  path_required: 'È richiesto il percorso al file dell\'Inventario',
  private_key_required: 'È richiesta la Chiave Privata',
  project_name_required: 'È richiesto il Nome del Progetto',
  repository_required: 'È richiesto il Repository',
  branch_required: 'È richiesto il Branch',
  key_required: 'È richiesta la Chiave',
  user_required: 'È richiesto l\'Utente',
  build_version_required: 'È richiesta la versione della Build',
  title_required: 'È richiesto il Titolo',
  isRequired: 'è richiesto',
  mustBeInteger: 'Deve essere un numero intero',
  mustBe0OrGreater: 'Deve essere uguale o maggiore di 0',
  start_version_required: 'È richiesta la Versione Iniziale',
  playbook_filename_required: 'È richiesto il nome del file del Playbook',
  inventory_required: 'È richiesto l\'Inventario',
  environment_required: 'È richiesto l\'Ambiente',
  email_required: 'È richiesta l\'Email',
  build_template_required: 'È richiesto il modello della Build',
  Task: 'Operazione',
  Build: 'Build',
  Deploy: 'Compilazione',
  Run: 'Esegui',

};
